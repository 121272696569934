import * as React from 'react';
import { Fragment, useState } from 'react';
import { Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import Container from './container';
import LogoIcon from '../images/logo-icon.svg';
import LogoText from '../images/logo-text.svg';
import MenuIcon from '../images/menu.svg';
import Link from './link';

const FocusTrap = require('focus-trap-react');

const navigationLinks = [
  { name: 'Services', to: '/services' },
  { name: 'About us', to: '/about' },
  { name: 'Our work', to: 'https://voltistudio.dribbble.com' },
  { name: 'Contact', to: '/contact' },
];

export default function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Container className="border-gray-200 border-b-[1px] md:border-0 lg:py-4">
      <div className="flex items-center justify-between h-16">
        <a className="flex items-center flex-shrink-0 space-x-2" href="/">
          <LogoIcon aria-hidden className="flex-shrink-0 inline-block" />
          <LogoText aria-label="Volti Studio" className="flex-shrink-0 h-6 -mt-px" />
        </a>

        <div className="flex items-center -mr-2 md:hidden">
          <button
            onClick={() => setIsOpen(true)}
            type="button"
            className="inline-flex items-center justify-center p-2 text-gray-600 rounded-lg hover:text-gray-800 hover:bg-gray-100"
          >
            <span className="sr-only">Open main menu</span>
            <MenuIcon className="w-6 h-6 fill-current" aria-hidden="true" />
          </button>
        </div>

        <div className="items-center hidden font-semibold md:text-xl md:ml-6 md:flex md:space-x-8">
          {navigationLinks.map(link => (
            <Link
              className="text-gray-700 border-b-4 border-transparent hover:text-indigo-500"
              currentClassName="text-indigo-600 border-indigo-500 border-b-4"
              to={link.to}
              key={link.name}
            >
              {link.name}
            </Link>
          ))}

          <Link
            to="/contact"
            className="items-center justify-center hidden px-5 py-3 ml-8 text-lg leading-none text-white bg-indigo-600 border border-transparent rounded-lg shadow-sm lg:inline-flex whitespace-nowrap hover:bg-indigo-700"
          >
            Let’s get started
          </Link>
        </div>

        <Transition
          show={isOpen}
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className="absolute inset-x-0 top-0 z-10 p-2 transition origin-top-right transform md:hidden">
            <FocusTrap focusTrapOptions={{ escapeDeactivates: false }}>
              <div className="overflow-hidden bg-white rounded-lg shadow-md ring-1 ring-black ring-opacity-5">
                <div className="flex items-center justify-between pt-4 pl-6 pr-5">
                  <a className="flex-shrink-0x" href="/">
                    <LogoText aria-label="Volti Studio" className="flex-shrink-0 h-6 -mt-1" />
                  </a>

                  <div className="-mr-2">
                    <button
                      type="button"
                      className="inline-flex items-center justify-center p-2 text-gray-500 bg-white rounded-lg hover:text-gray-600 hover:bg-gray-100"
                      onClick={() => setIsOpen(false)}
                    >
                      <span className="sr-only">Close menu</span>
                      <XIcon className="w-6 h-6" aria-hidden="true" />
                    </button>
                  </div>
                </div>
                <div className="px-4 py-3">
                  {navigationLinks.map(link => (
                    <Link
                      focus
                      className="flex items-center px-3 py-2 text-lg font-semibold text-gray-700 rounded-lg hover:bg-gray-100"
                      to={link.to}
                      key={link.name}
                      onClick={() => setIsOpen(false)}
                    >
                      {link.name}
                    </Link>
                  ))}
                </div>

                <div className="p-4 border border-t-gray-300">
                  <Link
                    to="/contact"
                    className="inline-flex items-center justify-center w-full px-5 py-2 text-lg text-white bg-indigo-600 border border-transparent rounded-lg shadow-sm whitespace-nowrap hover:bg-indigo-700"
                    onClick={() => setIsOpen(false)}
                  >
                    Let’s get started
                  </Link>
                </div>
              </div>
            </FocusTrap>
          </div>
        </Transition>
      </div>
    </Container>
  );
}
