import React from 'react';
import TwitterIcon from '../images/twitter.svg';
import LinkedinIcon from '../images/linkedin.svg';
import InstagramIcon from '../images/instagram.svg';
import DribbbleIcon from '../images/dribbble.svg';
import Facebook from '../images/facebook.svg';
import LogoIcon from '../images/logo-icon.svg';
import LogoText from '../images/logo-text.svg';
import { SOCIAL_LINKS } from '../constants';
import Container from './container';
import Link from './link';

const navigation = {
  services: [
    { name: 'Product strategy', to: '/services#strategy' },
    { name: 'Product design', to: '/services#design' },
    { name: 'Development', to: '/services#development' },
    { name: 'Maintenance', to: '/services#improvement' },
  ],
  company: [
    { name: 'About us', to: '/about' },
    { name: 'Our work', to: 'https://voltistudio.dribbble.com' },
    { name: 'Services', to: '/services' },
    { name: 'Contact', to: '/contact' },
  ],
};

const icons = {
  instagram: InstagramIcon,
  dribbble: DribbbleIcon,
  linkedin: LinkedinIcon,
  twitter: TwitterIcon,
  facebook: Facebook,
};

function LinksSection({ title, links }) {
  return (
    <div>
      <h2 className="text-base font-bold tracking-wider text-gray-400 uppercase">{title}</h2>
      <ul className="mt-3 space-y-3">
        {links.map(link => (
          <li key={link.name}>
            <Link
              className="text-lg text-gray-500 hover:text-indigo-500 hover:border-indigo-300 border-transparent border-b-[3px] inline-block leading-none"
              to={link.to}
            >
              {link.name}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}

function Footer() {
  return (
    <footer className="bg-gray-100 mt-28 xl:mt-44" aria-labelledby="footer-heading">
      <h1 id="footer-heading" className="sr-only">
        Footer
      </h1>
      <Container className="py-16">
        <div className="space-y-10 md:space-y-20 xl:space-x-20 xl:space-y-0 xl:flex">
          <div>
            <a className="flex items-center flex-shrink-0 space-x-2" href="/">
              <LogoIcon aria-hidden className="flex-shrink-0 inline-block" />
              <LogoText aria-label="Volti Studio" className="flex-shrink-0 h-6 -mt-px" />
            </a>
            <p className="mt-3 xl:mt-4 text-lg text-gray-500 md:max-w-[18em]">
              Building digital products that are simple, beautiful, and easy to use.
            </p>
            <div className="flex mt-4 space-x-4 xl:mt-6">
              {SOCIAL_LINKS.map(item => {
                const Icon = icons[item.id];

                return (
                  <a key={item.name} href={item.to} className="text-gray-500 hover:text-indigo-500">
                    <span className="sr-only">{item.name}</span>
                    <Icon className="w-6 h-6 fill-current" aria-hidden="true" />
                  </a>
                );
              })}
            </div>
          </div>
          <div className="flex flex-col justify-between flex-1 gap-8 md:gap-20 md:flex-row">
            <LinksSection title="Company" links={navigation.company} />
            <LinksSection title="Services" links={navigation.services} />
            <LinksSection title="Social" links={SOCIAL_LINKS} />

            <div className="space-y-4 xl:ml-auto">
              <div>
                <h2 className="text-base font-bold tracking-wider text-gray-400 uppercase">
                  Have a project in mind?
                </h2>
                <a
                  href="mailto:hello@voltistudio.com"
                  className="text-lg font-semibold text-indigo-500 transition-colors hover:text-indigo-600 hover:border-indigo-300 border-transparent border-b-[3px] leading-none inline-block"
                >
                  hello@voltistudio.com
                </a>
              </div>
              <div>
                <h2 className="text-base font-bold tracking-wider text-gray-400 uppercase">
                  Want to join us?
                </h2>
                <a
                  href="mailto:careers@voltistudio.com"
                  className="text-lg font-semibold text-indigo-500 transition-colors  hover:border-indigo-300 border-transparent border-b-[3px] hover:text-indigo-600 leading-none inline-block"
                >
                  careers@voltistudio.com
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="pt-6 mt-8 text-lg border-t-2 border-gray-200 md:flex md:items-baseline md:justify-between">
          <div className="flex items-center space-x-3 leading-none md:order-2">
            <Link
              className="text-gray-500 transition-colors hover:text-indigo-600 hover:border-indigo-300 border-transparent border-b-[3px] leading-none"
              to="/terms-of-service"
            >
              Terms of Service
            </Link>
            <span className="text-lg text-gray-400" aria-hidden>
              •
            </span>
            <Link
              className="text-gray-500 transition-colors hover:text-indigo-600 hover:border-indigo-300 border-transparent border-b-[3px] leading-none"
              to="/privacy-policy"
            >
              Privacy Policy
            </Link>
          </div>
          <p className="mt-2 text-gray-500 md:mt-0 md:order-1">
            &copy; 2022 Volti Studio, LLC. All rights reserved.
          </p>
        </div>
      </Container>
    </footer>
  );
}

export default Footer;
