import React from 'react';
import Helmet from 'react-helmet';
import { SEO } from '../constants';

function GlobalSEO() {
  return (
    <Helmet
      htmlAttributes={{ lang: SEO.lang || 'en' }}
      defaultTitle={SEO.baseTitle}
      titleTemplate={`%s | ${SEO.baseTitle}`}
      meta={[
        {
          name: 'author',
          content: SEO.author,
        },
        {
          name: 'description',
          content: SEO.description,
        },
        {
          property: 'og:title',
          content: SEO.baseTitle,
        },
        {
          property: 'og:description',
          content: SEO.description,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:creator',
          content: SEO.author,
        },
        {
          name: 'twitter:title',
          content: SEO.baseTitle,
        },
        {
          name: 'twitter:description',
          content: SEO.description,
        },
      ]}
    />
  );
}

export default GlobalSEO;
