import React from 'react';
import PropTypes from 'prop-types';
import GlobalSEO from './global-seo';
import Navbar from './navbar';
import Footer from './footer';

function Layout({ children }) {
  return (
    <>
      <GlobalSEO />

      <header>
        <Navbar />
      </header>

      <main>{children}</main>

      <Footer />
    </>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
