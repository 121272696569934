export const SEND_MAIL_URL = 'https://us-central1-voltistudio.cloudfunctions.net/callRequest';
// export const SEND_MAIL_URL = 'http://localhost:5001/voltistudio/us-central1/callRequest';

export const SEO = {
  baseTitle: 'Volti Studio',
  description: 'Volti Studio helps companies build amazing digital products.',
  author: 'Volti Studio',
};

export const EMAIL = 'hello@voltistudio.com';

export const SOCIAL_LINKS = [
  {
    id: 'linkedin',
    name: 'LinkedIn',
    to: 'https://www.linkedin.com/company/voltistudio',
  },
  {
    id: 'dribbble',
    name: 'Dribbble',
    to: 'https://dribbble.com/voltistudio',
  },
  {
    id: 'instagram',
    name: 'Instagram',
    to: 'https://www.instagram.com/voltistudio',
  },
  { id: 'twitter', name: 'Twitter', to: 'https://twitter.com/voltistudio' },
  {
    id: 'facebook',
    name: 'Facebook',
    to: 'https://www.facebook.com/voltistudio',
  },
];
