import * as React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link as GatsbyLink } from 'gatsby';

function Link({ children, to, className, currentClassName, onClick }) {
  const linkStyles = 'transition-colors';

  return to.startsWith('http') ? (
    <a
      href={to}
      className={classNames(linkStyles, className)}
      target="_blank"
      rel="noopener noreferrer"
      onClick={onClick}
    >
      {children}
    </a>
  ) : (
    <GatsbyLink
      getProps={({ isCurrent }) =>
        isCurrent && currentClassName ? { className: currentClassName } : {}
      }
      className={classNames(linkStyles, className)}
      to={to}
      onClick={onClick}
    >
      {children}
    </GatsbyLink>
  );
}

Link.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  currentClassName: PropTypes.string,
  to: PropTypes.string.isRequired,
};

Link.defaultProps = {
  className: null,
  currentClassName: null,
};

export default Link;
