import * as React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

function Container({ children, className }) {
  return (
    <section className={classNames('px-4 mx-auto max-w-7xl sm:px-6 lg:px-8', className)}>
      {children}
    </section>
  );
}

Container.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Container.defaultProps = {
  className: null,
};

export default Container;
